import { send } from "../index";

//  核销订单
export function getVerifyList(data, opts = {}) {
    return send({
        url: "/admin/orderBase/verification.do",
        method: "POST",
        data,
        ...opts,
    });
}

//  订单支付设置详情
export function getOrderSetting(data, opts = {}) {
    return send({
        url: "/admin/orderSetting/detail.do",
        method: "POST",
        data,
        ...opts,
    });
}

//  订单支付修改
export function updateOrderSetting(data, opts = {}) {
    return send({
        url: "/admin/orderSetting/update.do",
        method: "POST",
        data,
        ...opts,
    });
}

//  搜索核销订单
export function searchOrderVerification(data, opts = {}) {
    return send({
        url: "/admin/orderBase/searchList.do",
        method: "POST",
        data,
        ...opts,
    });
}

// 物流信息
export function orderTraces(data, opts = {}) {
    return send({
        url: "/admin/orderBase/orderTraces.do",
        method: "POST",
        data,
        ...opts,
    });
}

// 恢复订单列表
export function getSyncOrderList(data, opts = {}) {
    return send({
        url: "/admin/orderBase/syncOrderList.do",
        method: "POST",
        data,
        ...opts,
    });
}

// 恢复订单操作日志
export function getOperateLog(data, opts = {}) {
    return send({
        url: "/admin/operateLog/searchList.do",
        method: "POST",
        data,
        ...opts,
    });
}

// 恢复订单
export function syncOrder(data, opts = {}) {
    return send({
        url: "/admin/orderBase/syncOrder.do",
        method: "POST",
        data,
        ...opts,
    });
}

// 恢复订单
export function snackThirdVerify(data, opts = {}) {
    return send({
        url: "/admin/orderBase/snackThirdVerify.do",
        method: "POST",
        data,
        ...opts,
    });
}
