<template>
	<div>
		<a-form ref="form" :model="formState" name="form" style="margin-top: 20px;" scrollToFirstError
			 @finish="onSearch">
			<a-row >
				<a-form-item class="ui-form__item" label="卖品核销码" name="verificationCode" :rules="[{required: true, message: '必填项不允许为空'}]">
					<a-input ref="codeInput" v-model:value="formState.verificationCode" placeholder="请输入或扫描核销码"/>
				</a-form-item>
				<a-button type="primary" html-type="submit">查询</a-button>
				<a-button style="margin-left: 10px" @click="reset">重置</a-button>
			</a-row>
		</a-form>
		<a-spin :spinning="loading">
			<div style="margin-top: 10px;" v-if="snackList.length">
				<div class="ui-title">
					核销信息
					<div style="margin: auto 20px;" v-if="detail.status === 'WAIT_CHECK'"><a-button type="primary" @click="onVerification(detail.orderId)">核销</a-button></div>
				</div>
				<div class="ui-detail__item">核销状态：<a-tag color="green">{{ transOrderStatus(detail.status) }}</a-tag></div>
				<div v-if="detail.status === 'TRADE_FINISH'">
					<div class="ui-detail__item">核销员：{{ detail.verificationUserName || '--' }}</div>
					<div class="ui-detail__item">核销时间：{{ transDateTime(detail.finishTime) }}</div>
				</div>

				<div class="ui-title" style="margin-top: 20px;">
					所属影院：{{ detail.orderStoreVO.name || '--' }}
				</div>
				<a-table :columns="snackColumns" :dataSource="snackList" rowKey="id" :pagination="false">
					<template #bodyCell="{column, record}">
						<template v-if="column.key === 'image'">
							<a-image style="max-width: 300px;" :src="record.imgUrl"></a-image>
						</template>
						<template v-if="column.key === 'goods'">
							<div v-for="(item, i) in record.packageGoodsConfigVOS" :key="i" class="ui-goods__item">
                <a-image style="max-width: 100px;" :src="item.imgUrl"></a-image>
								{{ item.generalGoodsTitle }}
								<span>*{{ item.num }}</span>
							</div>
						</template>
						<template v-if="column.key === 'price'">
							<span style="color: #FD6846;">￥{{ record.nowPrice }}</span>
						</template>
					</template>
				</a-table>
			</div>
			<div v-if="list.length">
				<a-table style="margin-top: 10px;" :columns="columns" :dataSource="list" rowKey="id" :pagination="false">
					<template #bodyCell="{ column, record, index }">
						<template v-if="column.key === 'payInfo'">
							<div>订单编号：{{record.orderNo || '--'}}</div>
							<div>支付编号：{{record.outTradeNo || '--'}}</div>
							<div>支付状态：<span style="color: green;">{{transOrderStatus(record.status)}}</span></div>
							<div>支付方式：<a-tag style="margin-bottom: 8px;" :color="['#87d068', '#f50', '#108ee9', '#2db7f5', '', 'blue', '', '#999'][record.payType - 1]">{{ ['现金支付', '卖品券', '混合支付', '后台核销', '', '线上会员卡', '', '影城会员卡'][record.payType - 1] }}</a-tag></div>
							<div>交易总额：<span style="color: orange;">￥{{record.payment}}</span></div>
							<div>下单时间：<span style="color: orange;">{{transDateTime(record.createTime)}}</span></div>
						</template>

						<template v-else-if="column.key === 'userInfo'">
							<div v-if="record.orderVerifier">
								<div>会员昵称：{{ record.nickname || '--'}}</div>
								<div>会员手机：{{ record.phone }}</div>
							</div>
							<div v-else>--</div>
						</template>

						<template v-else-if="column.key === 'coupon'">
							<div v-if="record.snackUserCouponVOList && record.snackUserCouponVOList.length">
								券号：
								<span v-for="(item, i) in record.snackUserCouponVOList" :key="item">
									{{ item }}
									<span v-if="i < record.snackUserCouponVOList.length - 1">、</span>
								</span>
							</div>
							<div v-else>--</div>
						</template>

						<template v-else-if="column.key === 'deliveryInfo'">
							<div>
								<div>{{ ['无', '自提', '快递'][record.deliveryType - 1] }}</div>
								<div :id="'copys' + index">{{record.verificationCode}}</div>
								<a v-if="record.verificationCode" class="ui-copy__btn" @click="onCopy(index)">复制核销码</a>
							</div>
						</template>

						<template v-if="column.key == 'afterSaleMark'">
							<div v-if="record.orderAfterSale">
								<div style="color: red;">已退款</div>
								<div>退款金额：￥{{record.orderAfterSale.refund}}</div>
								<div>退款备注：{{record.orderAfterSale.description}}</div>
								<div>退款时间：{{transDateTime(record.orderAfterSale.createTime)}}</div>
							</div>
							<div v-else>--</div>
						</template>
					</template>
				</a-table>
			</div>
		</a-spin>
	</div>
</template>

<script>
	import {
		Icon
	} from '@/components/icon/icon.js';
	import {searchOrderVerification} from '@/service/modules/order.js';
	import {verificationOrderBase} from '@/service/modules/mall.js';
	export default {
		components: {
			Icon,
		},
		data() {
			return {
				loading: false,
				formState: {},
                searchData: {},
				detail: null,
				snackList: [],
				snackColumns: [{
					title: '卖品名称',
					dataIndex: 'mainTitle'
				}, {
					title: '卖品图片',
					key: 'image'
				}, {
					title: '包含物',
					key: 'goods',
					align: 'center'
				}, {
					title: '购买数量',
					dataIndex: 'buyNum',
					align: 'center'
				}, {
					title: '销售价格',
					key: 'price',
					align: 'center'
				}],
				list: [],
				columns: [{
					title: '取货信息',
					key: 'deliveryInfo',
					align: 'center',
				}, {
					title: '支付信息',
					key: 'payInfo'
				}, {
					title: '使用券信息',
					key: 'coupon'
				}, {
					title: '会员信息',
					key: 'userInfo'
				},{
					title: '售后退款备注',
					key: 'afterSaleMark'
				}],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
			}
		},
		created(){
			this.inputFocus();
		},
		methods: {
			onSearch() {
				this.pagination.current = 1;
				//this.pagination.pageSize = 10;
				this.detail = null;
				this.snackList = [];
				this.list = [];
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.getData();
			},
            async getData(){
				const postData = {
					page: this.pagination.current,
					pageSize: this.pagination.pageSize,
					saleType: 2,
					...this.searchData
				}
				this.loading = true;
				try {
					let {data} = await searchOrderVerification(postData);
					this.loading = false;
					data.list.forEach(item => {
						item.snackUserCouponVOList = [];
						item.goodsInfoVOS.forEach(subItem => {
							if (subItem.cardNumberList && subItem.cardNumberList.length) {
								item.snackUserCouponVOList = item.snackUserCouponVOList.concat(subItem.cardNumberList);
							}
						})
					})
					this.list = data.list;
					if (data.list.length) {
						this.detail = data.list[0];
						this.snackList = data.list[0].goodsInfoVOS;

					}
					this.pagination.total = data.totalCount;
				} catch (error) {
					this.loading = false;
				}
			},
			onVerification(orderId) {
				this.$confirm({
					title: '提示',
					content: '确定完成核销吗？',
					onOk: async () => {
						this.loading = true;
						try {
							let res = await verificationOrderBase({
								orderId,
							});
							this.loading = false;
							if (res.code === 200) {
								this.$message.success('核销成功');
								this.getData();
							}
						} catch (error) {
							this.loading = false;
						}
					}
				})
			},
			onCopy(index) {
				const range = document.createRange();
				range.selectNode(document.querySelector("#copys" + index));
				const selection = window.getSelection();
				if (selection.rangeCount > 0) selection.removeAllRanges();
				selection.addRange(range);
				document.execCommand('Copy');
				this.$message.success('复制成功');
			},
			reset() {
				this.$refs.form.resetFields();
				this.detail = null;
				this.snackList = [];
				this.list = [];
			},
			transOrderStatus(status){
				const orderStatus = {
					'WAIT_BUYER_PAY': '待支付',
					'SYSTEM_CANCEL': '系统超时取消',
					'CUSTOMER_CANCEL': '用户自行取消',
					'WAIT_SELLER_SEND_GOODS': '待发货',
					'WAIT_CHECK': '待核销',
					'TRADE_FINISH': '已核销',
					'WAIT_BUYER_CONFIRM_GOODS': '待收货',
					'AFTERSALE_FINISH': '交易关闭',
					'CHECKOUT_SUCCESS': '已付款',
					'TRADE_FINISH': '订单完成',
				}
				return orderStatus[status];
			},
			inputFocus() {
				this.$nextTick(() => {
					this.$refs.codeInput.focus()
				})
			},
		}
	}
</script>

<style scoped>
	.ui-form__item {
		width: 500px;
		margin-right: 30px;
	}
	.ui-title {
		display: flex;
		margin-bottom: 10px;
		align-items: center;
		font-size: 20px;
		font-weight: 600;
	}
	.ui-goods {
		padding: 6px 10px;
		border-radius: 10px;
		border: solid 1px #EEE;
	}
	.ui-goods + .ui-goods {
		margin-top: 4px;
	}
  .ui-goods__item {
    margin-top: 10px;
    padding: 10px 0;
    border: 1px solid #eee;
  }
</style>
